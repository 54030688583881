<template>
  <div class="manage-property-detail">
    <div class="container fluid">
      <div class="card p-2 md-p-3">
        <!-- Title Section -->
        <div class="d-flex align-items-center mb-5">
          <h1>Property Details</h1>
        </div>
        <!-- Details -->
        <property-detail
          ref="propertyDetail"
          :propertyDetails="propertyDetails"
          :isLoading="isPropertyDetailLoading"
          :canEdit="canEdit && !isAgency"
          @toListPage="toPropertyList"
          @toExclusive="toExclusivePage"
        ></property-detail>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyDetail from "@/components/GlobalComponents/Shared/Property/PropertyDetail";

export default {
  components: {
    PropertyDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canEdit: false,

      // Property Details
      isPropertyDetailLoading: false,
      propertyDetails: {}
    };
  },
  computed: {
    isAgency() {
      return this.$route.params.type == "agency" || false;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAccessPermission();
      await this.getProperty();
    },
    async getAccessPermission() {
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.update"
      );
    },
    toPropertyList() {
      this.$router.push({
        name: "ManageProperty"
      });
    },
    toExclusivePage(id) {
      this.$router.push({
        name: "ManagePropertyExclusive",
        params: {
          id: id
        }
      });
    },
    async getProperty() {
      try {
        this.isPropertyDetailLoading = true;

        let data = await this.$store.dispatch("manageProperty/getProperty", {
          id: this.$route.params.id
        });
        this.propertyDetails = this._.cloneDeep(data);
        this.$nextTick(() => {
          this.$refs.propertyDetail.initImages();
          this.isPropertyDetailLoading = false;
        });
      } catch (error) {
        this.isPropertyDetailLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch property details. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
