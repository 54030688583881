export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      otpToolTip: {
        depositRate1: `The first deposit, pay after OTP sign, % of the Purchase Price.<br>
                          <i>First Deposit + Second Deposit cannot be more than 10%.</i>`,
        depositAmount1: "The explicit amount for the first deposit.",
        depositRate2: `The second deposit, pay after deal\'s done, % of the Purchase Price. <br>
                          <i>First Deposit + Second Deposit cannot be more than 10%.</i>`,
        depositAmount2: "The explicit amount for the second deposit.",
        balancePaidWithinDays1:
          "The number of days which the <b>Second Deposit</b> shall be paid within.",
        balancePaidWithinDays2: `The number of days which the <b>Second Deposit</b> shall be paid within, 
                      from the date the Vendor obtained consent to transfer from the developer 
                      or approval from the relevant authorities.`,
        gracePeriod: "Addition grace period to the balance paid period.",
        interestRate:
          "Interest rate per annum on daily basis for balance not being paid on time.",
        spaExecutionDays1:
          "The number of working days which the SPA shall be executed within after buyer signed OTP.",
        spaExecutionDays2:
          "The number of working days which the vendor is allowed to execute the SPA, after the purchaser executed the SPA.",
        paymentAmount:
          "Explicit payment amount to the vendor attached to this OTP",
        otpValidDays:
          "The working day which vendor shall accept from the date hereof."
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
