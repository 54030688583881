<template>
  <div class="property-detail" fullscreen>
    <fd-button v-if="backNav" class="flat main mb-2" @click="$emit('toListPage')">
      <i class="fas fa-chevron-left mr-1"></i> Manage Property
    </fd-button>
    <!-- ======================= Skeleton Loading ======================== -->
    <template v-if="isLoading && propertyDetails !== null">
      <div class="row px-2 py-2">
        <div class="col-12 px-1">
          <p class="title w-50 d-inline-block skeleton"></p>
          <span class="title w-10 mx-1 d-inline-block skeleton"></span>
          <p class="w-25 skeleton"></p>
          <button class="btn skeleton my-2"></button>
        </div>
      </div>
      <div class="p-2 md-p-4">
        <div class="skeleton mb-2" style="width: 200px; height: 200px"></div>
        <fd-form-section v-for="(n, index) in 4" :key="index" title="">
          <div v-for="(n, index) in 5" :key="index" :class="detailCSS">
            <p class="w-25 skeleton mb-1"></p>
            <p class="w-75 skeleton"></p>
          </div>
        </fd-form-section>
      </div>
    </template>
    <!-- ======================= Property Details ======================== -->
    <template v-else>
      <div class="row px-2 py-2">
        <div class="col-12">
          <div class="row align-items-center">
            <exclusive-badge
              v-if="propertyDetails.isExclusive"
            ></exclusive-badge>
            <p class="title d-inline-block mr-1">{{ propertyDetails.title }}</p>
            <property-status
              :status="propertyDetails.status"
              class="d-inline-block"
            ></property-status>
          </div>
          <p>{{ propertyDetails.referenceCode }}</p>

          <!-- Listing Date -->
          <div class="row my-2">
            <p class="col-12 fg-grey-600">
              Created
              {{
                getDateDiff(propertyDetails.createdAt, {
                  full: true,
                  space: true
                })
              }}
              ago
            </p>
            <p class="col-12 fg-grey-600">
              Last Updated
              {{ $dayjs(propertyDetails.updatedAt).format("DD MMM YY H:mm A") }}
            </p>
          </div>

          <!-- ========================== ACTIONS ========================== -->
          <div class="mt-1">
            <fd-button
              v-if="canEdit && canUpdateMyProperty(propertyDetails.status)"
              class="bordered main mr-2 mb-1"
              @click="toEditPage(propertyDetails.id)"
            >
              <i class="fas fa-pen mr-1"></i>
              Edit
            </fd-button>
            <fd-button
              v-if="canEdit && canUpdateMyProperty(propertyDetails.status)"
              class="bordered main mr-2 mb-1"
              @click="$emit('repost', propertyDetails.id)"
            >
              <i class="fas fa-refresh mr-1"></i>
              Repost
            </fd-button>
            <fd-button
              v-if="
                canEdit &&
                canUpdateMyProperty(propertyDetails.status) &&
                propertyDetails.purpose != PURPOSE.AUCTION
              "
              class="bordered main mr-2 mb-1"
              @click="$emit('toExclusive', propertyDetails.id)"
            >
              <i class="fas fa-star mr-1"></i> Exclusive
            </fd-button>
            <fd-button
              v-if="
                canArchiveForRent(
                  propertyDetails.purpose,
                  propertyDetails.status
                )
              "
              class="bordered main mr-2 mb-1"
              @click="$emit('archiveRent', propertyDetails)"
            >
              <i class="fas fa-archive mr-1"></i> Archive For Rent
            </fd-button>
          </div>
        </div>
      </div>
      <hr class="row mx-2" />

      <div v-if="canEdit"  class="p-2">
        <h3 class="my-2">ACN</h3>
        <fd-button
          v-if="propertyDetails.allowPartnership"
          class="bordered main mr-2 mb-1"
          icon="fas fa-handshake-simple"
          @click="$emit('toManageACN', propertyDetails.id)"
        >
          Manage ACN
        </fd-button>
        <VPopover
          v-else
          :disabled="canActivateACN"
          trigger="click"
          placement="top"
        >
          <fd-button
            class="main mr-2 mb-1"
            icon="fas fa-handshake-simple"
            :disabled="!canActivateACN"
            @click="$emit('activateACN')"
          >
            Activate ACN
          </fd-button>
          <template slot="popover">
            <p>Only can active ACN if property is:
              <ul class="m-0">
                <li v-if="!isActive">Active</li>
                <li v-if="!isExclusive">Exclusive</li>
                <li v-if="!isForSale">For Sale</li>
              </ul>
            </p>
          </template>
        </VPopover>
        <hr class="mt-4" />
      </div>
      <div class="p-2">
        <!-- Pictures -->
        <div class="row mb-4">
          <cool-light-box
            :items="propertyImages"
            :index="propertyImageIndex"
            @close="propertyImageIndex = null"
            :slideshow="false"
          >
          </cool-light-box>
          <swiper
            class="swiper col-12 d-flex mxn-1 myn-1"
            :options="swiperOption"
          >
            <swiper-slide
              v-for="(url, imageIndex) in propertyImages"
              :key="imageIndex"
              class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
            >
              <div class="image-container">
                <div
                  class="image cursor-pointer"
                  @click="propertyImageIndex = imageIndex"
                >
                  <img :src="url" @error="$setAltPhoto" />
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
        <div
          v-if="!_.isEmpty(propertyDetails.vrLink)"
          class="video row justify-content-center mb-4"
        >
          <iframe
            :src="propertyDetails.vrLink"
            style="width: 600px; height: 400px"
            allowfullscreen
          ></iframe>
        </div>

        <fd-form-section title="Property Details">
          <div class="col-12 row mb-2">
            <p class="address col-12 mb-2">
              <i class="fas fa-map-marker-alt mr-1"></i>
              <span>{{ getPropertyAddress(propertyDetails) }}</span>
            </p>
            <MglMap
              v-if="lngLat"
              class="col-12"
              :style="{ height: '300px' }"
              :accessToken="mapGl.accessToken"
              :mapStyle="mapGl.mapStyle"
              :center="lngLat"
              :zoom="16"
            >
              <MglMarker :coordinates="lngLat" color="red"></MglMarker>
            </MglMap>
          </div>

          <div v-if="propertyDetails.hashtag" class="col-12 mb-3">
            <chip
              v-for="(tag, index) in propertyDetails.hashtag.split(',')"
              :key="index"
              class="mr-1"
              >{{ tag }}</chip
            >
          </div>

          <display-data
            :class="detailCSS"
            label="Purpose"
            :content="propertyDetails.purpose"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Property Category"
            :content="propertyDetails.propertyCategory"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Property Type"
            :content="propertyDetails.propertyType.name"
          >
          </display-data>
          <display-data
            v-if="propertyDetails.lotType"
            :class="detailCSS"
            label="Lot Type"
            :content="propertyDetails.lotType.name"
          >
          </display-data>
          <display-data
            v-if="propertyDetails.tenureType.length > 0"
            :class="detailCSS"
            label="Tenure Type"
          >
            <p
              v-for="(tenure, index) in propertyDetails.tenureType"
              :key="index"
            >
              {{ tenure }}
            </p>
          </display-data>
          <display-data
            :class="detailCSS"
            label="Furnishing Type"
            :content="propertyDetails.furnishingType"
          >
          </display-data>
        </fd-form-section>

        <fd-form-section title="Pricing Details">
          <div
            v-if="
              propertyDetails.purpose == PURPOSE.SALE ||
              propertyDetails.purpose == PURPOSE.SALE_AND_RENT ||
              propertyDetails.purpose == PURPOSE.AUCTION
            "
            class="col-6 sale pricing px-1"
          >
            <span class="title">Sale Price</span>
            <p>
              RM {{ $numberWithCommas(parseInt(propertyDetails.salePrice)) }}
            </p>
            <p class="per-unit">
              RM {{ propertyDetails.salePricePerUnit }}/{{
                propertyDetails.areaUnitType
              }}
            </p>
          </div>
          <div
            v-if="
              propertyDetails.purpose == PURPOSE.RENT ||
              propertyDetails.purpose == PURPOSE.SALE_AND_RENT
            "
            class="col-6 rent pricing px-1"
          >
            <span class="title">Rent Price</span>
            <p>
              RM {{ $numberWithCommas(parseInt(propertyDetails.rentPrice)) }}
            </p>
            <p class="per-unit">
              RM {{ propertyDetails.rentPricePerUnit }}/{{
                propertyDetails.areaUnitType
              }}
            </p>
          </div>
        </fd-form-section>
        <fd-form-section
          v-if="
            propertyDetails.purpose == PURPOSE.AUCTION &&
            propertyDetails.propertyAuction
          "
          title="Auction Detail"
        >
          <display-data
            :class="detailCSS"
            label="Time"
            :content="
              $moment(
                propertyDetails.propertyAuction.auctionTime,
                'H:mm'
              ).format('h:mm A')
            "
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Date"
            :content="propertyDetails.propertyAuction.auctionDate"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Venue"
            :content="propertyDetails.propertyAuction.auctionVenue"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Auction Type"
            :content="propertyDetails.propertyAuction.auctionType.name"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Plaintiff/Assignee"
            :content="propertyDetails.propertyAuction.assignee"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Auctioneer"
            :content="propertyDetails.propertyAuction.auctioneer"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Lawyer"
            :content="propertyDetails.propertyAuction.lawyer"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Defendant/Assignor"
            :content="propertyDetails.propertyAuction.assignor"
          ></display-data>
          <display-data
            :class="detailCSS"
            label="Deposit"
            :content="`${propertyDetails.propertyAuction.deposit} %`"
          ></display-data>
          <download-files
            label="Proclaimation Of Sale"
            class="col-12 mt-2 p-2"
            :downloadItem="propertyDetails.propertyAuction.proclamationOfSale"
            :filenamePrefix="`auction_property_pos_${propertyDetails.id}`"
          >
          </download-files>
        </fd-form-section>

        <fd-form-section title="Land Details">
          <div class="col-12 row d-flex">
            <display-data
              v-if="propertyDetails.landWidth"
              :class="detailCSS"
              label="Land Width"
            >
              {{ propertyDetails.landWidth }}/{{ propertyDetails.areaUnitType }}
            </display-data>
            <display-data
              v-if="propertyDetails.landDepth"
              :class="detailCSS"
              label="Land Width"
            >
              {{ propertyDetails.landDepth }}/{{ propertyDetails.areaUnitType }}
            </display-data>
            <display-data
              v-if="propertyDetails.landArea"
              :class="detailCSS"
              label="Land Area"
            >
              {{ propertyDetails.landArea }}/{{ propertyDetails.areaUnitType }}
            </display-data>
          </div>
          <div v-if="propertyDetails.builtUpWidth" class="col-12 row d-flex">
            <display-data
              v-if="propertyDetails.builtUpWidth"
              :class="detailCSS"
              label="Built Up Width"
            >
              {{ propertyDetails.builtUpWidth }}/{{
                propertyDetails.areaUnitType
              }}
            </display-data>
            <display-data
              v-if="propertyDetails.builtUpDepth"
              :class="detailCSS"
              label="Built Up Depth"
            >
              {{ propertyDetails.builtUpDepth }}/{{
                propertyDetails.areaUnitType
              }}
            </display-data>
            <display-data
              v-if="propertyDetails.builtUpArea"
              :class="detailCSS"
              label="Built Up Area"
            >
              {{ propertyDetails.builtUpArea }}/{{
                propertyDetails.areaUnitType
              }}
            </display-data>
          </div>
        </fd-form-section>

        <fd-form-section title="Facility Details">
          <display-data :class="detailCSS" label="Number of Bedroom">
            {{ propertyDetails.numberOfBedroom }}
          </display-data>
          <display-data :class="detailCSS" label="Number of Bathroom">
            {{ propertyDetails.numberOfBathroom }}
          </display-data>
          <display-data :class="detailCSS" label="Property Facilities">
            <span v-if="propertyDetails.propertyFacilities.length < 1"
              >No property facility</span
            >
            <span v-else>
              <ul>
                <li
                  v-for="facilities in propertyDetails.propertyFacilities"
                  :key="facilities.id"
                >
                  {{ facilities.name }}
                </li>
              </ul>
            </span>
          </display-data>
          <display-data :class="detailCSS" label="Property Amenities">
            <span v-if="propertyDetails.propertyAmenities.length < 1"
              >No property amenities</span
            >
            <span v-else>
              <ul>
                <li
                  v-for="amenities in propertyDetails.propertyAmenities"
                  :key="amenities.id"
                >
                  {{ amenities.name }}
                </li>
              </ul>
            </span>
          </display-data>
        </fd-form-section>

        <fd-form-section title="Description Info">
          <display-data class="col-12 mb-2 px-1" label="Description">
            <span v-if="$isStringEmpty(propertyDetails.description)">
              No Description provided.
            </span>
            <div
              v-else
              v-html="propertyDetails.description"
              class="wysiwyg-desc"
            ></div>
          </display-data>
          <display-data
            v-if="!$isStringEmpty(propertyDetails.videoLink)"
            class="col-12 mb-2 px-1"
            label="Video Link"
          >
            <a
              :href="$getFullAddress(propertyDetails.videoLink)"
              target="_blank"
              class="data"
              >{{ $getFullAddress(propertyDetails.videoLink) }}</a
            >
          </display-data>
          <display-data
            v-if="!$isStringEmpty(propertyDetails.videoLink)"
            class="col-12 sm-col-6 mb-2 px-1"
            label="Property Custom Categories"
          >
            <span
              v-if="propertyDetails.propertyCustomCategories < 1"
              class="data"
            >
              No custom category
            </span>
            <span v-else class="data comma-after">
              <span
                v-for="category in propertyDetails.propertyCustomCategories"
                :key="category.id"
                >{{ category.name }}</span
              >
            </span>
          </display-data>
        </fd-form-section>

        <fd-form-section title="Renovation Details">
          <display-data
            :class="detailCSS"
            label="Renovation Cost"
            :content="propertyDetails.renovationCost"
          >
          </display-data>
          <display-data
            class="col-12 sm-col-6 md-col-8"
            label="Renovation Detail"
            :content="propertyDetails.renovationDetail"
          >
          </display-data>
        </fd-form-section>

        <fd-form-section v-if="propertyDetails.owner" title="Property Owner">
          <display-data
            :class="detailCSS"
            label="Owner Name"
            :content="propertyDetails.owner.name"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Owner Contact"
            :content="propertyDetails.owner.contact"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Owner Email"
            :content="propertyDetails.owner.email"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Owner Remark"
            :content="propertyDetails.owner.remark"
          >
          </display-data>
        </fd-form-section>

        <fd-form-section
          v-if="checkCommissionExist()"
          title="Commission Details"
        >
          <display-data
            :class="detailCSS"
            label="Sales Commission Type"
            :content="propertyDetails.owner.remark"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Sales Commission"
            :content="`${propertyDetails.commission.salesCommissionValue}${
              propertyDetails.commission.salesCommissionType == 'Rate'
                ? ' %'
                : ''
            }`"
          >
          </display-data>
          <display-data
            :class="detailCSS"
            label="Rent Commission"
            :content="`${propertyDetails.commission.rentCommissionAmount}`"
          >
          </display-data>
        </fd-form-section>

        <!-- ============================================================= -->
        <!--                          OTP DETAIL                           -->
        <!-- ============================================================= -->
        <fd-form-section
          v-if="propertyDetails.offerToPurchase"
          title="Offer To Purchase (Default)"
          class="p-2"
          card
        >
          <div :class="detailCSS">
            <p>Purchase Price</p>
            <p class="data">
              {{ propertyDetails.offerToPurchase.purchasePriceNum }}
            </p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Property Address</p>
            <p class="data">
              {{ propertyDetails.offerToPurchase.propertyAddress }}
            </p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Additional Includes</p>
            <p class="data">
              {{
                propertyDetails.offerToPurchase.additionalInclude
                  ? propertyDetails.offerToPurchase.additionalInclude
                  : "None"
              }}
            </p>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Deposit Rate 1</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.depositRate1 }}%
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.depositRate1"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Deposit Amount 1</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.depositAmount1 }}
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.depositAmount1"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Deposit Rate 2</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.depositRate2 }}%
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.depositRate2"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Deposit Amount 2</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.depositAmount2 }}
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.depositAmount2"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Balance Paid Within</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.balancePaidWithinDays1 }}
                  days
                </p>
              </div>
              <!-- hint button -->
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.balancePaidWithinDays1"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Balance Paid Within (Approved)</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.balancePaidWithinDays2 }}
                  days
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.balancePaidWithinDays2"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Grace Period</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.gracePeriod }}
                  days
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.gracePeriod"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Interest Rate</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.interestRate }}%
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.interestRate"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>SPA Execute Within (Buyer)</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.spaExecutionDays1 }} days
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.spaExecutionDays1"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>SPA Execute Within (Vendor)</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.spaExecutionDays2 }} days
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.spaExecutionDays2"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>OTP Validity</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.otpValidDays }} days
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.otpValidDays"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <div class="row">
              <div class="col-10">
                <p>Payment Amount</p>
                <p class="data">
                  {{ propertyDetails.offerToPurchase.paymentAmount }}
                </p>
              </div>
              <button
                type="button"
                class="btn flat col-2"
                v-tooltip="otpToolTip.paymentAmount"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div :class="detailCSS">
            <p>Vendor Name</p>
            <p class="data">
              {{ propertyDetails.offerToPurchase.vendorName }}
            </p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Special Condition</p>
            <p class="data">
              {{
                propertyDetails.offerToPurchase.specialCondition
                  ? propertyDetails.offerToPurchase.specialCondition
                  : "None"
              }}
            </p>
          </div>
        </fd-form-section>

        <!-- ============================================================= -->
        <!--                          OTR DETAIL                           -->
        <!-- ============================================================= -->
        <fd-form-section
          v-if="propertyDetails.offerToRent"
          title="Offer To Rent (Default)"
          class="p-2"
          card
        >
          <display-data
            :class="['col-12', otrSpacing]"
            label="Property Type"
            :content="propertyDetails.offerToRent.propertyType"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Property/Premises for rent"
            :content="propertyDetails.offerToRent.propertyAddress"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Landlord's Postal Address"
            :content="propertyDetails.offerToRent.landlordAddress"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Tenant Name"
            :content="propertyDetails.offerToRent.tenantName"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Tenant Adderess"
            :content="propertyDetails.offerToRent.tenantAddress"
          >
          </display-data>
          <hr class="col-12" />
          <display-data
            :class="['col-12', otrSpacing]"
            label="Monthly Rent"
            :content="`RM ${propertyDetails.offerToRent.rentPrice} / month`"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Bill Inclusive"
            :content="propertyDetails.offerToRent.billInclusive"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Rent in Advance"
            :content="propertyDetails.offerToRent.rentInAdvance"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Security Deposit"
            :content="propertyDetails.offerToRent.securityDeposit"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Utilities Deposit"
            :content="propertyDetails.offerToRent.utilitiesDeposit"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Other Deposit"
            :content="propertyDetails.offerToRent.otherDeposit"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Other Deposit's Remark"
            :content="propertyDetails.offerToRent.remark"
          >
          </display-data>
          <display-data
            :class="['col-12', otrSpacing]"
            label="Subtotal"
            :content="propertyDetails.offerToRent.subtotal"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Earnest Deposit"
            :content="propertyDetails.offerToRent.earnestDeposit"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Balance To Pay"
            :content="propertyDetails.offerToRent.balanceToPay"
          >
          </display-data>
          <hr class="col-12" />
          <display-data
            :class="['col-12', otrSpacing]"
            label="Commencement Date"
            :content="propertyDetails.offerToRent.commencementDate"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6', otrSpacing]"
            label="Rent Free Period granted (From)"
            :content="propertyDetails.offerToRent.rentFreePeriodFrom"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6', otrSpacing]"
            label="Rent Free Period granted (To)"
            :content="propertyDetails.offerToRent.rentFreePeriodTo"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Terms of Tenancy"
            :content="`${propertyDetails.offerToRent.tenancyTerms} Years`"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Renew Term"
            :content="`${propertyDetails.offerToRent.renewTerm} Years`"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Renew Price"
            :content="propertyDetails.offerToRent.renewFurtherTerm"
          >
          </display-data>
          <hr class="col-12" />
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Total Disbursement"
            :content="propertyDetails.offerToRent.totalDisbursement"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="T.A. to be Borne By"
            :content="propertyDetails.offerToRent.borneBy"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Total Disbursement"
            :content="propertyDetails.offerToRent.borneBy"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Parking Lot Unit"
            :content="`${propertyDetails.offerToRent.parkingLotUnit} Units`"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Viewing Date"
            :content="propertyDetails.offerToRent.viewingDate"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Other Terms & Condition"
            :content="propertyDetails.offerToRent.otherCondition"
          >
          </display-data>
          <hr class="col-12" />
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="TA Execute Within"
            :content="`${propertyDetails.offerToRent.workingDayTA} Business Days`"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="Landlord/Appointed Solicitor"
            :content="propertyDetails.offerToRent.payeeName"
          >
          </display-data>
          <display-data
            :class="['col-12 sm-col-6 md-col-4 xl-col-3', otrSpacing]"
            label="OTR Execute Within"
            :content="`${propertyDetails.offerToRent.workingDayOTR} Business Days`"
          >
          </display-data>
        </fd-form-section>
      </div>
    </template>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/skeleton.scss";
import otpToolTipMixin from "@/mixins/otpToolTipText";
import { purpose, propertyStatus } from "@/enums";
import { getDateDiff } from "@/utils/datetime";
import { getPropertyAddress } from "@/utils/property";
import { MglMap, MglMarker } from "vue-mapbox";
import { VPopover } from "v-tooltip";

export default {
  components: {
    VPopover,
    MglMap,
    MglMarker,
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles"),
    PropertyStatus: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertyStatus"),
    ExclusiveBadge: () =>
      import("@/components/GlobalComponents/Shared/Property/ExclusiveBadge")
  },
  mixins: [otpToolTipMixin],
  props: {
    propertyDetails: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    backNav: {
      type: Boolean,
      default: true,
    },
    detailRouteName: {
      type: String,
      default: "ManagePropertyEdit"
    }
  },
  data: function () {
    return {
      detailCSS: "col-12 sm-col-6 md-col-3 mb-1 px-1",
      otrSpacing: "px-1 mb-2",

      PURPOSE: purpose,
      STATUS: propertyStatus,

      propertyImages: [],
      propertyImageIndex: null,

      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          click: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },

      mapGl: {
        accessToken: process.env.VUE_APP_MAPBOX_PK,
        mapStyle: "mapbox://styles/mapbox/streets-v11"
      },

      getDateDiff,
      getPropertyAddress
    };
  },
  computed: {
    lngLat() {
      if (
        this._.isEmpty(this.propertyDetails.longitude) ||
        this._.isEmpty(this.propertyDetails.latitude)
      ) {
        return null;
      }
      return [this.propertyDetails.longitude, this.propertyDetails.latitude];
    },
    isForSale() {
      return (
        this.propertyDetails.purpose === purpose.SALE ||
        this.propertyDetails.purpose === purpose.SALE_AND_RENT
      );
    },
    isExclusive() {
      return this.propertyDetails.isExclusive;
    },
    isActive() {
      return this.propertyDetails.status === propertyStatus.ACTIVE;
    },
    canActivateACN() {
      return this.isExclusive && this.isForSale && this.isActive;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    canUpdateMyProperty(status) {
      if (status == this.STATUS.DEALT_BY_OTHER || status == this.STATUS.SOLD) {
        return false;
      }
      return true;
    },
    canArchiveForRent(purpose, status) {
      let isRent =
        purpose === this.PURPOSE.SALE_AND_RENT || purpose === this.PURPOSE.RENT;
      let isNotClosed =
        status === this.STATUS.ACTIVE || status === this.STATUS.INACTIVE;
      if (isRent && isNotClosed) {
        return true;
      }
      return false;
    },
    initImages() {
      if (this.propertyImages.length < 1) {
        this.propertyImages.push(
          this.$getFileURL(this.propertyDetails.mainPhoto[0])
        );
        // Check if has gallery then append
        if (this.propertyDetails.gallery.length > 0) {
          let galleryImages = this.propertyDetails.gallery.map((item) => {
            return this.$getFileURL(item);
          });
          this.propertyImages.push(...galleryImages);
        }
      }
    },
    toEditPage(id) {
      this.$router.push({ name: this.detailRouteName, params: { id: id } });
    },
    checkCommissionExist() {
      if (this.propertyDetails.commission) {
        return false;
      } else if (
        this.propertyDetails.commission.rentCommissionAmount == "" &&
        this.propertyDetails.commission.salesCommissionType == "" &&
        this.propertyDetails.commission.salesCommissionValue == ""
      ) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.property-detail {
  width: 100%;

  .btn i {
    font-size: 12px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    &.skeleton {
      width: 100%;
      height: 28px;
      border-radius: 4px;
    }
  }
  .address {
    font-size: 16px;
  }
  .status {
    background: $color-secondary;
    color: white;
    border-radius: 4px;
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
  }
  .content {
    max-height: 60vh;
    overflow-y: auto;
    .data {
      font-size: 15px;
    }
    .photos {
      width: 100%;
      max-width: 300px;
    }

    .pricing {
      .title {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }

    .comma-after {
      & > *:not(:last-child)::after {
        content: ", ";
      }
    }
  }
  .partnership-status {
    span {
      color: white;
      border-radius: 4px;
      padding: 5px 10px;
      &.allowed {
        background: $color-success;
      }
      &.not-allowed {
        background: $color-warn;
      }
    }
  }
}

.image-container {
  @include flex(row, center, center);
  border: 1px solid #ddd;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .image {
    display: block;
    width: 100%;
    img {
      @include image(cover);
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
}

@media #{$breakpoint-down-sm} {
  .property-details {
    width: 100%;
    .status {
      display: block;
      width: fit-content;
      margin: 4px 0px;
    }
  }
}
</style>
